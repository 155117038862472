import { DatePipe } from '@angular/common';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from './constants';

export class FormatDate {
    static convertDateToStringDate(date: Date): string {
        const d = new DatePipe(Constants.DATE_PIPE_LOCALE).transform(date, Constants.FORMAT_STRING_DATE);

        if (d) {
            return d;
        }

        return '';
    }

    static convertNgbDateToStringDate(ngbDate: NgbDate | null | undefined): string {
        if (ngbDate) {
            const newDate = new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
            return this.convertDateToStringDate(newDate);
        }
        return '';
    }

    static convertStringDateToNgbDate(date: string): NgbDate | null {
        const dateParsed = date?.split('-');
        // TODO: Establish if this is the best approach
        if (dateParsed.length >= 2) return new NgbDate(+dateParsed[0], +dateParsed[1], +dateParsed[2]);
        else {
            return null;
        }
    }

    static convertDateToNgbDate(date: Date): NgbDate {
        return new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
    }

    static convertNgbDateToDate(date: NgbDate): Date {
        return new Date(date.year, date.month - 1, date.day);
    }

    static subtractDaysFromDate(date: string, days: number): string {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() - days);
        return this.convertDateToStringDate(newDate);
    }

    static countMonthsBetweenDates(startDate: Date, endDate: Date) {
        return (endDate.getFullYear() - startDate.getFullYear()) * 12 + (endDate.getMonth() - startDate.getMonth());
    }

    static addDaysToDate(date: string, days: number): string {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() + days);
        return this.convertDateToStringDate(newDate);
    }

    static getFirstDayOfMonthAsDate(date: Date): Date {
        return new Date(date.getFullYear(), date.getMonth(), 1);
    }

    static getFirstDayOfMonth(date: Date): string {
        return this.convertDateToStringDate(new Date(date.getFullYear(), date.getMonth(), 1));
    }

    static getLastDayOfMonth(date: Date): string {
        return this.convertDateToStringDate(new Date(date.getFullYear(), date.getMonth() + 1, 0));
    }

    static getLastDayOfNextMonth(date: Date): string {
        const nextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1);
        const lastDayOfMonth = new Date(nextMonth.getFullYear(), nextMonth.getMonth() + 1, 0);
        return this.convertDateToStringDate(lastDayOfMonth);
    }

    static getLastDayOfMonthAsNumber(date: Date): number {
        return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    }

    static getFirstDayOfNextYear(date: Date): string {
        return this.convertDateToStringDate(new Date(date.getFullYear() + 1, 0, 1));
    }

    static getFirstDayOfPrevious5Year(date: Date): string {
        return this.convertDateToStringDate(new Date(date.getFullYear() - 5, 0, 1));
    }

    static getLastDayOfPreviousMonth(date: Date): string {
        return this.convertDateToStringDate(new Date(date.getFullYear(), date.getMonth(), 1));
    }

    static getLastDayOfNext5Year(date: Date): string {
        return this.convertDateToStringDate(new Date(date.getFullYear() + 5, 11, 31));
    }

    static getFirstDayOfYear(date: Date): string {
        return this.convertDateToStringDate(new Date(date.getFullYear(), 0, 1));
    }

    static getLastDayOfYear(date: Date): string {
        return this.convertDateToStringDate(new Date(date.getFullYear(), 11, 31));
    }

    static getLastDayOfNextYear(date: Date): string {
        return this.convertDateToStringDate(new Date(date.getFullYear() + 1, 11, 31));
    }

    /**
     * Calculates the number of days between two given dates.
     * @param {Date} startDate - The start date of the period.
     * @param {Date} endDate - The end date of the period.
     * @returns {number} - The number of days between the start and end dates.
     */
    static countDaysOfPeriod(startDate: Date, endDate: Date): number {
        return (endDate.valueOf() - startDate.valueOf()) / (1000 * 60 * 60 * 24);
    }

    /**
     * Calculates the number of weekend days (Saturdays and Sundays) between two given dates.
     * @param {Date} startDate - The start date of the period.
     * @param {Date} endDate - The end date of the period.
     * @returns {number} - The number of weekend days between the start and end dates.
     */
    static countWeekendDays(startDate: Date, endDate: Date) {
        const ndays = 1 + Math.round((endDate.getTime() - startDate.getTime()) / (24 * 3600 * 1000));
        const nsaturdays = Math.floor((startDate.getDay() + ndays) / 7);
        return 2 * nsaturdays + (startDate.getDay() === 0 ? 1 : 0) - (endDate.getDay() == 6 ? 1 : 0);
    }

    /**
     * Calculates the date that is `months` months after the given date.
     * If `months` is not provided, it defaults to 1 month.
     * @param {Date} date - The base date.
     * @param {number} [months = 1] - The number of months to add to the date.
     * @returns {Date} - The calculated date.
     */
    static nextMonth(date: Date, months: number = 1): Date {
        date.setMonth(date.getMonth() + months);
        return date;
    }

    /**
     * Calculates the date that is `months` months before the given date.
     * If `months` is not provided, it defaults to 1 month.
     * @param {Date} date - The base date.
     * @param {number} [months = 1] - The number of months to subtract from the date.
     * @returns {Date} - The calculated date.
     */
    static prevMonth(date: Date, months: number = 1): Date {
        date.setMonth(date.getMonth() - months);
        return date;
    }
}
